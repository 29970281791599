<template>
    <div :is="layout">
        <div ref="myDiv" id="cesiumContainer">

        </div>
    </div>
</template>
<script>
    import {wind3D} from "./lib/app";
    import {get} from "../../api/tracks";

    export default {
        name: 'free_route',
        data: () => ({
            layout: null,
            viewer: null,
        }),
        methods: {
            add_flight_line() {
// 获取航线
                this.viewer.entities.add({
                    // availability: new this.Cesium.TimeIntervalCollection([ new this.Cesium.TimeInterval({ start: start, stop: stop }) ]),
                    // position: positionProperty,
                    // path: new this.Cesium.PathGraphics({ width: 0 }),
                    // orientation: new this.Cesium.VelocityOrientationProperty(positionProperty),
                    // model : {
                    //     uri : ' https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb',
                    //     minimumPixelSize : 64
                    // },
                    polyline: {
                        show: true,
                        positions: this.Cesium.Cartesian3.fromDegreesArray([
                             -122.37371644,37.61949703,
                             121.80697880,31.13605227
                        ]),
                        material: this.Cesium.Color.YELLOW
                    }
                });
            },
            init() {
                this.viewer = wind3D()
                const entity = this.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(-90, 40, 100),
                    polygon: {
                        show: true,
                        hierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray([
                            -90, 40,
                            -91, 40,
                            -92, 40,
                            -92, 39,
                            -92, 38,
                            -92, 37
                        ])),
                        material: Cesium.Color.GREEN
                    },

                })
                this.add_flight_line()
                // this.viewer.flyTo(entity)
            }

        },
        mounted() {

            this.layout = this.$route.meta.use_layout
            this.$nextTick(() => {
                this.init()
            })
        },
    }
</script>
<style lang="stylus" scoped>
 #cesiumContainer
    height calc(100vh - 100px)
</style>
