import { Panel, demo } from "./gui";
import Wind3D from "./wind3D";

export const mode = {
    debug: demo ? false : true
};

export const panel = new Panel();
export const wind3D = () => {
    const wind3d = new Wind3D(
        panel,
        mode
    );
    return wind3d.viewer
}
